.ae-logo {
  width: 100%;
  max-width: 13.875rem;

  @media (width >= #{$size-sm}) {
    max-width: 25rem;
  }

  svg {
    display: block;
    width: 100%;
    height: auto;
    fill: var(--color-white);
  }
}

.landing-arrow {
  @media (width < #{$size-sm}) {
    width: 21px;
    height: 13px;
  }
}