ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

button,
input,
select,
textarea,
button{
  appearance: none;
  border: 0;
  padding: 0;
  background: none;
  border-radius: 0;
  line-height: inherit;
  font: inherit;
  resize: none;
  letter-spacing: inherit;
  color: inherit;
  text-transform: inherit;
}

figure,
blockquote {
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
  text-align: inherit;
}