.categories-list {
  overflow: hidden;
}

.categories-list__inner {
  display: flex;
  flex-wrap: wrap;
  margin: calc(var(--margin-10) * -0.5) calc(var(--margin-10) * -1);

  li {
    margin: calc(var(--margin-10) * 0.5) var(--margin-10);
  }

  a {
    font-weight: 700;

    &.is-active {
      padding-bottom: 1px;
      border-bottom: 2px solid;
    }
  }
}