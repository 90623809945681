.h-rip {
  position: relative;
  overflow: hidden;
  height: 60px;
  width: 100%;

  svg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    height: 100%;
    width: var(--rip-size);
    min-width: 100%;
  }
}

.h-rip-small {
  height: 40px;

  svg {
    width: calc(var(--rip-size) * (40/60));
  }
}

.h-rip-top,
.h-rip-bottom {
  position: absolute;
  left: 0;
}

.h-rip-top {
  top: 0;
}

.h-rip-bottom {
  bottom: 0;
}

.h-rip-pull {
  transform: translateY(-100%);
}

.h-rip-push {
  transform: translateY(100%);
}

.v-rip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.v-rip-left {
  svg {
    position: absolute;
    top: 10%;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    max-width: 40%;
    max-height: 80%;
  }
}

.v-rip-right {
  svg {
    position: absolute;
    bottom: 10%;
    right: 0;
    width: 100%;
    height: 100%;
    max-width: 40%;
    max-height: 80%;
  }
}
