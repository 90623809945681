.donate-mini-form {
  width: 100%;
  max-width: 20em;
}

.donate-radio-container {
  font-family: var(--font-title-stack);
  text-transform: uppercase;
  min-height: var(--button-size);
  display: flex;
  
  @media (--xl) {
    font-size: var(--font-size-h5-xl);
    line-height: var(--font-lh-h5-xl);
  }
  @media (--lg) {
    font-size: var(--font-size-h5-lg);
    line-height: var(--font-lh-h5-lg);
  }
  @media (--md) {
    font-size: var(--font-size-h5-md);
    line-height: var(--font-lh-h5-md);
  }
  @media (--sm) {
    font-size: var(--font-size-h5-sm);
    line-height: var(--font-lh-h5-sm);
  }
  @media (--xs) {
    font-size: var(--font-size-h5-xs);
    line-height: var(--font-lh-h5-xs);
  }
  @media (--xxs) {
    font-size: var(--font-size-h5-xxs);
    line-height: var(--font-lh-h5-xxs);
  }
}

.donate-radio {
  position: relative;
  flex: 1 0 auto;
  text-align: center;

  &:first-child label {
    border-radius: var(--button-size) 0 0 var(--button-size);
  }

  &:last-child label {
    border-radius: 0 var(--button-size) var(--button-size) 0;
    border-right-width: 2px;
  }

  input {
    display: none;
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--color-black);
    border-right-width: 0;
    user-select: none;

    &:hover {
      background-color: var(--color-green-alt);
    }
  }

  input:checked + label {
    background-color: var(--color-black);
    color: var(--color-green);
  }  
}

.donate-input-wrap {
  font-family: var(--font-title-stack);
  text-transform: uppercase;
  min-height: var(--button-size);
  display: flex;
  border-radius: var(--button-size);
  padding: 12px calc(var(--button-size) * 0.5) var(--margin-10);
  transition: color 350ms, background-color 350ms, border-color 350ms;
  border: 2px solid;
  background-color: var(--color-green-alt);

  @media (--xl) {
    font-size: var(--font-size-h5-xl);
    line-height: var(--font-lh-h5-xl);
  }
  @media (--lg) {
    font-size: var(--font-size-h5-lg);
    line-height: var(--font-lh-h5-lg);
  }
  @media (--md) {
    font-size: var(--font-size-h5-md);
    line-height: var(--font-lh-h5-md);
  }
  @media (--sm) {
    font-size: var(--font-size-h5-sm);
    line-height: var(--font-lh-h5-sm);
  }
  @media (--xs) {
    font-size: var(--font-size-h5-xs);
    line-height: var(--font-lh-h5-xs);
  }
  @media (--xxs) {
    font-size: var(--font-size-h5-xxs);
    line-height: var(--font-lh-h5-xxs);
  }
}

.donate-input {
  -moz-appearance: textfield;
  width: 100%;
  text-align: center;
  padding-right: calc(var(--button-size) * 0.5);

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }


  &::placeholder {
    color: var(--color-black);
  }

  &:focus {
    &::placeholder {
      color: transparent;
    }
  }
}

.donate-input-prepend {
  margin-right: calc(var(--button-size) * 0.5);
}

.donate-form-row {
  margin-top: var(--margin-20);

  button {
    width: 100%;
  }
}

[data-donate-form="monthly"] {
  .donate-price-one-off {
    display: none;
  }
}

[data-donate-form="one-off"] {
  .donate-price-monthly {
    display: none;
  }
}