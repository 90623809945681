*, *:before, *:after {
  box-sizing: border-box;
}

body {
  background-color: #fff;
}

.site {
  flex: 1 0 auto;
}

.preload * {
  transition: none !important;
}

.sticky-container {
  position: fixed;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  max-height: calc(100vh - var(--ios-gap) - var(--header-height));
  display: flex;
  flex-direction: column;
}

.wrap {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.skip-to-content-link {
  background: #fff;
  border: 2px solid #000;
  left: 0;
  top: 0;
  padding: 8px;
  position: absolute;
  z-index: 101;
  transform: translateY(-100%);
  transition: transform 0.3s;
  font-weight: 700;
  border-radius: 0;
}

.skip-to-content-link:focus {
  transform: translateY(0%);
  left: 5px;
  top: 5px;
}