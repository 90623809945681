.section {
  padding: 0.05px 0;
  position: relative;
}

.section-with-margins {
  @media (width >= #{$size-sm}) {
    margin: var(--margin-90) 0;

    &:first-child {
      margin-top: var(--margin-45);
    }
  }
}

.section-title {
  margin-bottom: var(--margin-20);
  padding-bottom: var(--margin-20);
  border-bottom: 1px solid var(--color-grey);

  @media (width >= #{$size-sm}) {
    margin-bottom: var(--margin-45);
    padding-bottom: var(--margin-45);
  }
}

.relative {
  position: relative;
}

.container {
  padding-right: var(--margin-20);
  padding-left: var(--margin-20);
  width: 100%;
  max-width: $size-lg;
  margin-right: auto;
  margin-left: auto;

  @media (width >= #{$size-sm}) {
    padding-right: var(--margin-90);
    padding-left: var(--margin-90);
  }
}

.container--pad-v {
  padding-top: var(--margin-20);
  padding-bottom: var(--margin-20);

  @media (width >= #{$size-sm}) {
    padding-top: var(--margin-90);
    padding-bottom: var(--margin-90);
  }
}

.tear-underlay {
  position: relative;

  @media (width < #{$size-md}) {
    margin-top: calc(var(--margin-20) * -1);
  }

  @media (--sm) {
    margin-top: calc(var(--margin-90) * -1);
  }

  @media (width >= #{$size-md}) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.collapse {
  padding: 0.05px 0;
}

.section-meta {
  display: flex;

  @media (width >= #{$size-md}) {
    justify-content: flex-end;
  }
}