.small-cta {
  position: relative;
  top: 0;
  z-index: 100;
  display: none;
  transition: all 500ms;

  &.is-active {
    display: block;
  }

  .container {
    padding-top: var(--margin-10);
    padding-bottom: var(--margin-10);
    min-height: 3.75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (width >= #{$size-sm}) {
      padding-top: var(--margin-15);
      padding-bottom: var(--margin-15);
    }
  }

  .is-nav-active & {
    display: none;
  }
}

.small-cta__close {
  position: absolute;
  padding: var(--margin-15);
  top: 0;
  right: 0;

  @media (width >= #{$size-sm}) {
    top: 50%;
    transform: translateY(-50%);
  }

  svg {
    display: block;
    width: 1.0625rem;
    height: 1.0625rem;

    @media (width >= #{$size-sm}) {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

.small-cta__inner {
  display: flex;
  flex-wrap: wrap;

  @media (width < #{$size-sm}) {
    padding-right: 2.9375rem;
  }
}

.cta-link {
  display: inline-block;

  @media (width < #{$size-sm}) {
    &:not(:last-child) {
      margin-bottom: var(--margin-20);
    }
  }

  @media (width >= #{$size-sm}) {
    margin: calc(var(--margin-20) * 0.5);
  }
}