.site-footer {
  position: relative;
  background-color: #2A2A29;
}

.site-footer__logo {
  width: 100%;
  max-width: 13.875rem;

  @media (width >= #{$size-sm}) {
    max-width: 16.5625rem;
  }

  svg {
    display: block;
    width: 100%;
    height: auto;
    fill: var(--color-white);
  }
}

.site-footer__nav {
  a {
    display: inline-block;
  }

  div:not(:last-child) {
    margin-bottom: 8px;
  }
}

.footer-rip {
  overflow: hidden;
  height: 336px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  svg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    height: 100%;
    width: 2560px;
    min-width: 100%;
  }
}

.site-footer__split-col {
  @media (width >= #{$size-sm}) {
    display: flex;
    justify-content: space-between;
  }
}