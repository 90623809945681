.page-content {
  @media (width >= #{$size-sm}) {
    & > .content-module:last-child {
      margin-bottom: var(--margin-90);
    }

    & > .content-module[class*=bg]:last-child {
      margin-bottom: 0;
    }
  }
}

.content-section-bordered {
  margin-bottom: var(--margin-30);
  padding-bottom: var(--margin-30);
  border-bottom: 1px solid var(--color-grey);

  @media (width >= #{$size-sm}) {
    margin-bottom: var(--margin-90);
    padding-bottom: var(--margin-90);
  }
}

.content-section {
  margin: var(--margin-30) 0;

  @media (width >= #{$size-sm}) {
    margin: var(--margin-90) 0;
  }

  &:first-child {
    margin-top: 0;
  }
}

.content-module {
  margin: var(--margin-30) 0;

  @media (width >= #{$size-sm}) {
    margin: var(--margin-45) 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.sidebar {
  overflow: hidden;
  
  @media (width >= #{$size-md}) {
    position: sticky;
    top: calc(var(--header-height) + var(--margin-20));
  }
}

.sidebar__inner {
  @media (--sm) {
    margin-right: calc(var(--gutter-sm) * -0.5);
    margin-left: calc(var(--gutter-sm) * -0.5);
    margin-top: calc(var(--margin-45) * -1);
    margin-bottom: calc(var(--margin-45) * -1);
    display: flex;
    flex-wrap: wrap;

    .content-module {
      width: 50%;
      padding-right: calc(var(--gutter-sm) * 0.5);
      padding-left: calc(var(--gutter-sm) * 0.5);
      margin: var(--margin-45) 0;
    }
  }
}