@media (width < #{$size-sm}) {
  .card-row {
    .card:nth-child(n+2) {
      display: none;
    }
  }
}

@media (--sm) {
  .card-row {
    .card:nth-child(n+3) {
      display: none;
    }
  }
}