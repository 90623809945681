
/* ACL related CSS */
.wForm .wf-acl-hidden { display: none !important;}

/* wFORMS REPEATABLE SECTION ---------------------------------------------- */

/* Repeated section (when not on a fieldset) */
.wForm div.repeat { }
/* Removeable section (when not on a fieldset) */
.wForm div.removeable { }

/* 'Repeat' Link (generated by the Repeat Behavior) */
.wForm .duplicateSpan { text-align: right; }
.wForm .duplicateLink {}

/* 'Remove' link (generated by the Repeat Behavior) */
.wForm .removeSpan { float: right; }
.wForm .removeLink {}

/* Repeat/Remove link when used in a table row */
.wForm td .duplicateSpan, .wForm td .removeSpan { line-height: 100%; display: inline; padding: 5px 2px 0 8px;  }


/* FIELD HINTS  ------------------------------------------------------------ */

/* default styling */
.wForm .field-hint .hint              { opacity: 1;  }
.wForm .field-hint-inactive .hint         { opacity: .5; }

.wForm .field-hint                  { background-color: #FFFFFF; }

/* Displayed on the side (with or without focus) */
.wForm .hintsSide .field-hint,
.wForm .hintsSide .field-hint-inactive        { display: inline-block; vertical-align:top; white-space: normal !important; }
.wForm .hintsSide .field-hint .hint,
.wForm .hintsSide .field-hint-inactive .hint    { display: inline-block; padding: 0 5px; }

/* Displayed as a popup (with focus) */
.wForm .hintsTooltip .field-hint          { display: block; position: absolute; z-index: 50;
                            min-width: 200px;
                            -webkit-box-shadow:  0 2px 8px rgba(0,0,0,0.3);
                            -moz-box-shadow: 0 2px 8px rgba(0,0,0,0.3);
                            box-shadow: 0 2px 8px rgba(0,0,0,0.3);
                          }
.wForm .hintsTooltip .field-hint .hint        { display: block; padding: 5px;  }

/* Displayed as a popup (without focus = hidden) */
.wForm .hintsTooltip .field-hint-inactive       { display: none; }


.wForm .hintsBelow .field-hint .hint,
.wForm .hintsBelow .field-hint-inactive .hint   { padding: 0; }

/* Local override */
.wForm .oneField.hintsTooltip .field-hint     { position: absolute; z-index: 50; display: block;
                            min-width: 200px;
                            -webkit-box-shadow:  0 2px 6px rgba(0,0,0,0.3);
                            -moz-box-shadow: 0 2px 6px rgba(0,0,0,0.3);
                            box-shadow: 0 2px 6px rgba(0,0,0,0.3);
                          }
.wForm .oneField.hintsTooltip .field-hint .hint   { display: block; }
.wForm .oneField.hintsTooltip .field-hint-inactive  { display: none; }
.wForm .oneField.hintsTooltip .hint         { padding: 5px; }

.wForm .oneField.hintsSide .field-hint,
.wForm .oneField.hintsSide .field-hint-inactive   { display: inline-block; position: static;
                            min-width: 0;
                            -webkit-box-shadow: none;
                            -moz-box-shadow: none;
                            box-shadow: none;
                            vertical-align:top; white-space: normal !important; }
.wForm .oneField.hintsSide .hint          { padding: 0 5px; }

.wForm .oneField.hintsBelow .field-hint,
.wForm .oneField.hintsBelow .field-hint-inactive  { display: block; position: static;
                            min-width: 0;
                            -webkit-box-shadow: none;
                            -moz-box-shadow: none;
                            box-shadow: none; }
.wForm .oneField.hintsBelow .hint           { padding: 0; }


/* Uploaded Files ----------------------------------------------------------- */
.wForm form .uploadDelete .fileName {
  text-decoration: line-through;
  opacity:0.2;
}
.wForm form .uploadKeep .fileName {
  text-decoration: none;
  color: #000;
}


/* MISC.   ------------------------------------------------------------------ */

.wFormContainer .linkedErrMsg {  border: 2px solid transparent; color: blue; }
.wFormContainer .errLink { text-decoration: underline; color: blue; }
.wFormContainer .errFld { }

.wForm .captchaHelp {
    font-size: 85%;
    opacity: 0.75;
    padding: 0 8px;
    margin-top: 0.75em;
}
.wForm fieldset.captcha .preField {  min-width: 0; width: 75px !important; }
.wForm fieldset.captcha input {  width: 110px !important; }
.wForm fieldset.captcha p.instructions { margin-top: 0.75em; }
.wForm fieldset.captcha img { border: 1px solid #999999; padding: 2px; margin-bottom: 1em; }


/* Make readonly calculated fields look like plain text */
.wForm .readonly { background-color: transparent !important; border: none !important; background-image: none !important; }

/* JS ONLY CSS - Normally present is separate wforms-jsonly.css ------------- */

/* Handle conditional section visibility */
.wForm form .onstate-a,  .wForm form .onstate-b, .wForm form .onstate-c,  .wForm form .onstate-d,
.wForm form .onstate-e,  .wForm form .onstate-f, .wForm form .onstate-g,  .wForm form .onstate-h,
.wForm form .onstate-i,  .wForm form .onstate-j, .wForm form .onstate-k,  .wForm form .onstate-l,
.wForm form .onstate-m,  .wForm form .onstate-n, .wForm form .onstate-o,  .wForm form .onstate-p,
.wForm form .onstate-q,  .wForm form .onstate-r, .wForm form .onstate-s,  .wForm form .onstate-t,
.wForm form .onstate-u,  .wForm form .onstate-v, .wForm form .onstate-w,  .wForm form .onstate-x,
.wForm form .onstate-y,  .wForm form .onstate-z, .wForm form .onstate-aa, .wForm form .onstate-ab,
.wForm form .onstate-ac, .wForm form .onstate-ad,.wForm form .onstate-ae, .wForm form .onstate-af,
.wForm form .onstate-ag, .wForm form .onstate-ah,.wForm form .onstate-ai, .wForm form .onstate-aj,
.wForm form .onstate-ak, .wForm form .onstate-al,.wForm form .onstate-am, .wForm form .onstate-an,
.wForm form .onstate-ao, .wForm form .onstate-ap,.wForm form .onstate-aq, .wForm form .onstate-ar,
.wForm form .onstate-as, .wForm form .onstate-at,.wForm form .onstate-au, .wForm form .onstate-av,
.wForm form .onstate-aw, .wForm form .onstate-ax,.wForm form .onstate-ay, .wForm form .onstate-az,
.wForm form .onstate-ba, .wForm form .onstate-bb,.wForm form .onstate-bc, .wForm form .onstate-bd,
.wForm form .onstate-be, .wForm form .onstate-bf,.wForm form .onstate-bg, .wForm form .onstate-bh,
.wForm form .onstate-bi, .wForm form .onstate-bj,.wForm form .onstate-bk, .wForm form .onstate-bl {
  display: block !important;
}
/* offstate classes for the Switch Behavior are in wforms-jsonly.css */


/* Auto Format related*/
.wForm .autoformatprompt .label{
    color: #dc143c;
}

.wForm .autoformatprompt .delete{
    color: #b0c4de;
}

.wForm .autoformatprompt .noinput{
    color: #b0c4de;
}

.wForm .autoformatprompt .input{
    font-weight: bold;
}

.wForm .autoformatprompt{
    position: absolute;
    z-index: 127;
    padding: 3px 0px 0px 2px;
    white-space: nowrap;
}

.wForm .autoformatprompt-control{
    background-color: transparent;
    position: relative;
    z-index: 128;
}
.wForm .autoformatprompt-hook{
    z-index: 125;
}

.wForm .autoformat-background{
  z-index: 126;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
}

/* "Response Submitted" confirmation page */
.wForm .wFormThankYou           {  }

/* "Response Saved" confirmation page */
.wForm .wFormsCustomMessage       { font-size: 22px; margin-top: 15px; }
.wForm .wFormsResumeInstructions li   { font-size:16px; line-height:150%; }
.wForm .wFormsSavedInstructions     { margin-top: 15px; }

#wFormsResumeList th { text-align: left; }




/* Save & Resume dialog */
.saveAndResume      { text-align:right; font-size: small; }
.saveAndResume
  fieldset      { text-align:left; }
.saveAndResume
  .preField       { width: 9em !important; min-width: 9em  !important; }
.saveAndResume
  .postField      { margin-right:0; }
.saveAndResume
  .instructions     { margin: 1em 6px; }
.saveAndResume
  #tfa_resumeEmail  { width: 195px; }
.saveAndResume
  #tfa_resumePassword { width: 195px; }
.saveAndResume
  #tfa_confirmPassword { width: 195px;}
.saveAndResume .actions
  #tfa_resumeLater.wfHideSubmit
            { display: inline !important; }
.onstate-resumelater  { display: block }


/* Generic FA Error Messages */
.errorMessage         { margin: 10px 15px; padding: 15px; font-family:sans-serif; font-weight: bold; color: #D46161!important; }
.errorMessage a       { text-decoration: none;  font-weight: normal; }
.wForm .forgotPasswordLink  { font-size: small; }
.wForm .assistanceLink    { font-size: small; }
.wForm .startNewLink    { font-size: small; }


/* Thank you & Help messages */
.wFormThankYou      { min-height: 150px; }
.wFormHelp        { min-height: 150px; }

/* Review page */
.reviewHeader, .reviewResponse, .reviewFooter { padding: 20px; }
.reviewResponse .responseData .switchedOff    { display: none; }
.reviewResponse .responseData  .showSwitchedOff .switchedOff,
.reviewResponse .responseData  .showSwitchedOff .switchedOff .question { color: #BBB !important; }

.reviewResponse .typehidden { display: none; } /* Hide hidden field in the response review */

.reviewFooter .reviewActions { text-align: left; }
.reviewFooter .reviewActions img { vertical-align: middle;}

.reviewResponse .responseData .page {
  margin: 1.2em 0;
  padding-bottom: 1.2em;
  border-bottom: 2px dotted #CCC;
}

/* Fieldset / Grid layout */
.reviewResponse .responseData .fieldset {
  margin: 1.2em 0;
  border: 1px solid #BBB;
  padding: 10px;
  -moz-border-radius: 0.5em;
  -webkit-border-radius: 0.5em;
  border-radius: 0.5em;

}
.reviewResponse .responseData .fieldset h4 {
  margin: 0;
}
.reviewResponse .responseData .inlineDiv {
  margin: 1em 0 0 0;
}
.reviewResponse .responseData table {
  border-collapse: collapse;
}
.reviewResponse .responseData table td {
  vertical-align: top;
}
.reviewResponse .responseData .grid {
  border: 1px solid #eee;
}
.reviewResponse .responseData .grid th {
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 5px 8px 5px 5px;
  text-align: left;
  font-weight: normal;
  color: #656565;
}
.reviewResponse .responseData .grid .alternate {
  background-color: #F4F5F4;
}
.reviewResponse .responseData .grid td {
  border: 1px solid #eee;
}
/* Question Label */
.reviewResponse .responseData .grid .question {
  padding: 5px 5px 5px 5px;
}
.reviewResponse .responseData table .question {
  padding: 1em 8px 0 0;
  margin: 0;
}
.reviewResponse .responseData .question {
  margin: 1em 0 0 0;
}
.reviewResponse .responseData table .unknownField {
  padding: 5px 8px 5px 0;
  text-align: right;
}
/* User Response (free text) */
.reviewResponse .responseData .grid .response {
  /* border-right: 1px solid #eee; */
  padding: 5px 5px 5px 5px;
}
.reviewResponse .responseData table .response {

}
.reviewResponse .responseData .response {
  font-weight: bold;
}
/* User Response (Multiple Choice) */
.reviewResponse .responseData .response ul {
  padding: 0;
  margin: 0;
}
.reviewResponse .responseData .response ul .singleAnswer {
  margin: 0  !important;
  padding: 0 !important;
  border-bottom: none !important;
  list-style-type: none !important;
  font-weight: bold;
}
.reviewResponse .responseData .response ul .multipleAnswer {
  margin: 0 0 0 15px !important;
  padding: 0 !important;
  font-weight: bold;
}
.reviewResponse .attachedFiles h4 { font-style: italic; font-weight: normal; margin: 0 0 6px 0;}

@media print {
   .reviewActions, .reviewHeader{ display: none; }
   .responseData h3, .responseData h4 { page-break-after: avoid; }
   /* Following not enabled.  Can be added manually in branding on a case-by-case basis. */
   /* .responseData .fieldset { page-break-inside: avoid; } */
   /* .responseData .page { page-break-after: always; } */
}


/* Tabbed Navigation for multi-page forms */
.wfTabCurrentPage       { font-weight: bold; }
#wfTabNav               { font-size: smaller; border: 2px solid transparent; padding: 2px 6px; text-align: right; }
.wfTab, .wfTabSep   { display: inline-block; }



/* Autosuggest feature styles: */
.wForm div .typeahead,
.wForm div .tt-query,
.wForm div .tt-hint {
  width: auto;
  min-width: 164px;
  height: 30px;
  padding: 8px 12px;
  font-size: inherit;
  line-height: inherit;
  border: 2px solid #ccc;
  outline: none;
}

.wForm div .typeahead {
  background-color: #fff;
}

.wForm div .typeahead:focus {
  border: 2px solid #0097cf;
}

.wForm div .tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
     -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.wForm div .tt-hint {
  color: #999;
  margin:0px;
  padding: 0px;
  margin-top: -6px;
}

.wForm div .tt-menu {
  box-sizing: border-box;
  width: 100%;
  min-width: 164px;
  margin: 0px;
  padding: 0px;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
     -moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
          box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

.wForm div .tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: #0097cf;
}

.wForm div .tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0097cf;
}

.wForm div .tt-suggestion, .wForm div .tt-empty-message {
  margin: 0;
  padding: 0 6px;
}

.wForm div .tt-empty-message {
  color: #767676;
  font-style: italic;
}

.wForm div .tt-more-message {
  font-size: 0.7em;
  padding: 0 6px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.wForm div .tt-menu {
  line-height: 2em;
  max-height: calc(2em * 5.5);
  overflow-y: auto;
}

.wForm div .rtl-support .tt-menu {
  text-align: right;
}

.wForm .tt-spinner {
/*  display:none;
  margin-left: -22px;
  margin-top: 3px;
  margin-bottom: -3px;
  position: absolute;*/
  position: absolute;
  top: 3px;
  right: 2px;
  display: none;
}
.wForm .tt-caret-down {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}
.wForm .tt-search {
  position: absolute;
  top: 28%;
  left: 3%;
  z-index: 5;
  cursor: pointer;
}

.wForm .tt-clear {
  position: absolute;
  top: 28%;
  right: 3%;
  z-index: 5;
  cursor: pointer;
}
.wForm .tt-clear.no-input {
  visibility: hidden;
}

.wFormContainer .wForm .inputWrapper input[type="text"][data-dataset-json],
.wFormContainer .wForm .inputWrapper input[type="text"][data-dataset-id] {
  padding-left: 25px;
  padding-right: 25px;
}
/* End autosuggest styles */

/* Recaptcha scaling */
@media only screen
and (max-width: 355px) {
  #google-captcha {transform:scale(0.8);-webkit-transform:scale(0.8);transform-origin:0 0;-webkit-transform-origin:0 0;}
}
/* End Recaptcha scaling */

.sensitive-value-omitted { padding: 5px 0 0 0!important; }

/* response caching dialog */

/* for modals */
.wFormOverlay {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}
.wFormOverlay .doNotAskAgain {
  display: inline-block;
}

.wFormOverlay #save-to-cache-dialog {
  background-color: #FFF;
}

.wFormOverlay .primaryAction {
  margin-right: 1em  !important;
}
