@media (width < #{$size-sm}) {
  .xs\:hide {
    display: none;
  }
}

@media (--sm) {
  .sm\:hide {
    display: none;
  }
}

@media (width >= #{$size-md}) {
  .md\:hide {
    display: none;
  }
}