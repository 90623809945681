$sizes: 90, 45, 30, 20, 15, 10, 0;

@each $size in $sizes {
  .m-t-$size {
    margin-top: calc(#{$size} * 1px);
  }

  .m-r-$size {
    margin-right: calc(#{$size} * 1px);
  }

  .m-b-$size {
    margin-bottom: calc(#{$size} * 1px);
  }

  .m-l-$size {
    margin-left: calc(#{$size} * 1px);
  }
}

@media (width < #{$size-sm}) {
  @each $size in $sizes {
    .xs\:m-t-$size {
      margin-top: calc(#{$size} * 1px);
    }

    .xs\:m-r-$size {
      margin-right: calc(#{$size} * 1px);
    }

    .xs\:m-b-$size {
      margin-bottom: calc(#{$size} * 1px);
    }

    .xs\:m-l-$size {
      margin-left: calc(#{$size} * 1px);
    }
  }
}

@media (--sm) {
  @each $size in $sizes {
    .sm\:m-t-$size {
      margin-top: calc(#{$size} * 1px);
    }

    .sm\:m-r-$size {
      margin-right: calc(#{$size} * 1px);
    }

    .sm\:m-b-$size {
      margin-bottom: calc(#{$size} * 1px);
    }

    .sm\:m-l-$size {
      margin-left: calc(#{$size} * 1px);
    }
  }
}