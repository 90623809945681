.cols {
  @media (width >= #{$size-sm}) {
    display: flex;
    flex-wrap: wrap;
  }

  @media (--xl) {
    margin-right: calc(var(--gutter-xl) * -0.5);
    margin-left: calc(var(--gutter-xl) * -0.5);
  }
  @media (--lg) {
    margin-right: calc(var(--gutter-lg) * -0.5);
    margin-left: calc(var(--gutter-lg) * -0.5);
  }
  @media (--md) {
    margin-right: calc(var(--gutter-md) * -0.5);
    margin-left: calc(var(--gutter-md) * -0.5);
  }
  @media (--sm) {
    margin-right: calc(var(--gutter-sm) * -0.5);
    margin-left: calc(var(--gutter-sm) * -0.5);
  }
  @media (--xs) {
    margin-right: calc(var(--gutter-xs) * -0.5);
    margin-left: calc(var(--gutter-xs) * -0.5);
  }
  @media (--xxs) {
    margin-right: calc(var(--gutter-xxs) * -0.5);
    margin-left: calc(var(--gutter-xxs) * -0.5);
  }
}

.col {
  flex: 0 0 auto;

  @media (--xl) {
    padding-right: calc(var(--gutter-xl) * 0.5);
    padding-left: calc(var(--gutter-xl) * 0.5);
  }
  @media (--lg) {
    padding-right: calc(var(--gutter-lg) * 0.5);
    padding-left: calc(var(--gutter-lg) * 0.5);
  }
  @media (--md) {
    padding-right: calc(var(--gutter-md) * 0.5);
    padding-left: calc(var(--gutter-md) * 0.5);
  }
  @media (--sm) {
    padding-right: calc(var(--gutter-sm) * 0.5);
    padding-left: calc(var(--gutter-sm) * 0.5);
  }
  @media (--xs) {
    padding-right: calc(var(--gutter-xs) * 0.5);
    padding-left: calc(var(--gutter-xs) * 0.5);
  }
  @media (--xxs) {
    padding-right: calc(var(--gutter-xxs) * 0.5);
    padding-left: calc(var(--gutter-xxs) * 0.5);
  }
}

@media (width >= #{$size-sm}) {
  .cols--center {
    flex-direction: column;
    align-items: center;
  }

  .cols--flex-end {
    align-items: flex-end;
  }

  .col--left {
    align-self: flex-start;
  }

  .split-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .flex {
    display: flex;
    align-items: stretch;
  }
}

@media (--sm) {
  @for $i from 1 to 2 {
    .sm\:col-$i {
      width: calc(($i/2) * 100%);
    }

    .offset-left-sm-$i {
      margin-left: calc(($i/2) * 100%);
    }
  }
}

@media (width >= #{$size-md}) {
  @for $i from 1 to 6 {
    .col-$i {
      width: calc(($i/6) * 100%);
    }

    .offset-left-$i {
      margin-left: calc(($i/6) * 100%);
    }
  }

  .cols--reverse {
    flex-direction: row-reverse;
  }
}