.opportunity {
  border-top: 1px solid var(--color-grey);
  padding: 25px 0;
}

.opportunity__inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.opportunity__meta {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.opportunity__link {
  border-radius: 50px;
  border: 1px solid;
  padding: 5px 15px;
  transition: background-color 350ms, color 350ms, border-color 350ms;

  &:hover {
    background-color: var(--color-black);
    color: var(--color-white);
    border-color: var(--color-black);
  }
}

.sidebar__block {
  border-top: 1px solid var(--color-grey);
}

.sidebar__block-list {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sidebar__block-title {
  cursor: pointer;
  padding: 25px 0;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar__block-arrow {
  width: 16px;
  height: 10px;
  transform: rotate(0deg);
}

.sidebar__block-details[open] .sidebar__block-arrow {
  transform: rotate(180deg);
}

.sidebar__block-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sidebar__block-item input {
  width: 22px;
  height: 22px;
  background-color: var(--color-grey);

  &:checked {
    background-color: var(--color-red);
  }
}

.sidebar__block-summary {
  list-style: none;

  &::-webkit-details-marker {
    display: none;
  }
}

.sidebar__actions {
  padding: 25px 0;
}

.filter-control {
  display: flex;
  flex-direction: column;
}

.filter-control__button {
  cursor: pointer;

  @media (width >= #{$size-sm}) {
    display: none;
  }
}

.filter-control__content {
  @media (width < #{$size-sm}) {
    display: none;
  }
}

.filter-control.is-open .filter-control__content {
  display: block;
}
