.large-cta {
  padding: var(--gutter-xs) 0;
  flex: 0 1 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: none;

  &.is-active {
    display: block;
  }

  @media (width >= #{$size-sm}) {
    padding: var(--margin-30) 0;
  }
}

.large-cta__title {
  @media (width < #{$size-sm}) {
    padding-right: 60px;
  }
}

.large-cta__close {
  position: absolute;
  padding: var(--margin-15);
  top: 0;
  right: 0;
  padding: var(--gutter-xs);

  @media (width >= #{$size-sm}) {
    top: var(--margin-15);
    right: var(--margin-15);
    padding: var(--margin-15);
  }

  svg {
    display: block;
    width: 1.0625rem;
    height: 1.0625rem;
    
    @media (width >= #{$size-sm}) {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}