.social-icons-wrap {
  overflow: hidden;
}

.social-icons {
  display: flex;
  margin: calc(var(--margin-10) * -0.5);
}

.social-icon {
  display: block;
  width: 26px;
  height: 26px;
  margin: calc(var(--margin-10) * 0.5);
  transition: opacity 350ms;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  &:hover {
    opacity: 0.5;
  }
}