.cookie-notice {
  position: fixed;
  z-index: 200;
  display: none;
  bottom: 0;
  left: 0;
  padding: var(--margin-20);
  animation: cookieEnter 1250ms 350ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
  animation-fill-mode: both;
  /* background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%); */

  &.is-active {
    display: block;
  }
}

.cookie-notice__body {
  width: 100%;
  background-color: var(--color-white);
  border-top: 2px solid;
  padding: var(--margin-20);
  box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
  animation: bounce 6000ms 6000ms infinite;

  @media (width >= #{$size-sm}) {
    max-width: 620px;
  }

  .button {
    text-transform: none;
  }
}

@keyframes cookieEnter {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  4% {
    transform: translateY(-10px);
  }
  8% {
    transform: translateY(0);
  }
  12% {
    transform: translateY(-10px);
  }
  16% {
    transform: translateY(0);
  }
}