.card-grid {
  @media (width < #{$size-sm}) {
    .card {
      margin-bottom: var(--margin-20);
    } 
  }

  @media (width >= #{$size-sm}) {
    margin-bottom: calc(var(--margin-90) * -1);

    .card {
      margin-bottom: var(--margin-90);
    }
  }
}