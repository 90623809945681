.z-low {
  z-index: 1;
}

.z-mid {
  z-index: 3;
}

.z-high {
  z-index: 5;
}