.entry-tools {
  @media (width >= #{$size-sm}) {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}

.entry-tools__pagination {
  display: flex;
  
  @media (width < #{$size-sm}) {
    justify-content: space-between;

    .entry-tools__link {
      width: 33.33%;

      &:nth-child(2) {
        text-align: center;
      }

      &:nth-child(3) {
        text-align: right;
      }
    }
  }

  @media (width >= #{$size-sm}) {
    .entry-tools__link {
      margin-left: var(--margin-20);
    }
  }
}

.entry-sharer {
  display: flex;
  align-items: baseline;
}

.entry-tools__link {
  a {
    display: inline-flex;
    align-items: center;
  }
}

.entry-tools__prev {
  svg {
    transition: transform 350ms;
    margin-right: 7px;
    margin-top: -1px;
  }

  @media (hover: hover) {
    a:hover {
      svg {
        transform: translateX(-5px);
      }
    }
  }
}

.entry-tools__next {
  svg {
    transition: transform 350ms;
    margin-left: 7px;
    margin-top: -1px;
  }

  @media (hover: hover) {
    a:hover {
      svg {
        transform: translateX(5px);
      }
    }
  }
}