.button {
  font-family: var(--font-title-stack);
  text-transform: uppercase;
  min-height: var(--button-size);
  border-radius: var(--button-size);
  padding: 12px calc(var(--button-size) * 0.5) var(--margin-10);
  transition: color 350ms, background-color 350ms, border-color 350ms;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (--xl) {
    font-size: var(--font-size-h5-xl);
    line-height: var(--font-lh-h5-xl);
  }
  @media (--lg) {
    font-size: var(--font-size-h5-lg);
    line-height: var(--font-lh-h5-lg);
  }
  @media (--md) {
    font-size: var(--font-size-h5-md);
    line-height: var(--font-lh-h5-md);
  }
  @media (--sm) {
    font-size: var(--font-size-h5-sm);
    line-height: var(--font-lh-h5-sm);
  }
  @media (--xs) {
    font-size: var(--font-size-h5-xs);
    line-height: var(--font-lh-h5-xs);
  }
  @media (--xxs) {
    font-size: var(--font-size-h5-xxs);
    line-height: var(--font-lh-h5-xxs);
  }
}

.button--green {
  background-color: var(--color-green);
  color: var(--color-black);

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-black);
      color: var(--color-green);
    }
  }
}

.button--outline {
  border: 2px solid;

  @media (hover: hover) {
    &:hover {
      color: var(--color-white);
      background-color: var(--color-black);
      border-color: var(--color-black);
    }

    .t-color-white & {
      &:hover {
        color: var(--color-black);
        background-color: var(--color-white);
        border-color: var(--color-white);
      }
    }

    [class*="bg-color-"]:not(.bg-color-white) & {
      &:hover {
        color: var(--color-highlight);
        background-color: var(--color-text);
        border-color: var(--color-text);
      }
    }
  }
}

.button-block {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @media (width < #{$size-sm}) {
    & > *:not(:first-child) {
      margin-top: var(--margin-20);
    }
  }

  @media (width >= #{$size-sm}) {
    flex-direction: row;
    align-items: center;
    margin: calc(var(--margin-20) * -0.5);

    & > * {
      margin: calc(var(--margin-20) * 0.5);
    }
  }
}

.button-block--vertical {
  display: flex;
  flex-direction: column;

  @media (width >= #{$size-sm}) {
    display: inline-flex;
  }

  .button + .button {
    margin-top: var(--margin-20);
  }
}