body {
  font-family: var(--font-sans-stack);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0;
}

.divider {
  border: 0;
  border-top: 1px solid var(--color-grey);
  margin: var(--margin-30) 0;

  @media (width >= #{$size-sm}) {
    margin: var(--margin-90) 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.divider--small {
  border: 0;
  border-top: 1px solid var(--color-grey);
}

.t-wrap {
  overflow: hidden;
}

.t-baseline {
  padding: 0.05px 0;

  &::before { 
    content: "";  
    margin-top: -0.3023em;  
    display: block; 
    height: 0;  
    width: 100%;
  }

  &::after {  
    content: "";  
    margin-bottom: -0.3203em; 
    display: block; 
    height: 0;  
    width: 100%;
  }
}

.t-baseline-t {
  padding-top: 0.05px;

  &::before { 
    content: "";  
    margin-top: -0.3023em;  
    display: block; 
    height: 0;  
  }
}

.t-baseline-b {
  padding-bottom: 0.05px;

  &::after { 
    content: "";  
    margin-bottom: -0.3023em;  
    display: block; 
    height: 0;  
  }
}

.t-h1 {
  font-family: var(--font-title-stack);

  @media (--xl) {
    font-size: var(--font-size-h1-xl);
    line-height: var(--font-lh-h1-xl);
  }
  @media (--lg) {
    font-size: var(--font-size-h1-lg);
    line-height: var(--font-lh-h1-lg);
  }
  @media (--md) {
    font-size: var(--font-size-h1-md);
    line-height: var(--font-lh-h1-md);
  }
  @media (--sm) {
    font-size: var(--font-size-h1-sm);
    line-height: var(--font-lh-h1-sm);
  }
  @media (--xs) {
    font-size: var(--font-size-h1-xs);
    line-height: var(--font-lh-h1-xs);
  }
  @media (--xxs) {
    font-size: var(--font-size-h1-xxs);
    line-height: var(--font-lh-h1-xxs);
  }
}

.t-h2  {
  font-family: var(--font-title-stack);

  @media (--xl) {
    font-size: var(--font-size-h2-xl);
    line-height: var(--font-lh-h2-xl);
  }
  @media (--lg) {
    font-size: var(--font-size-h2-lg);
    line-height: var(--font-lh-h2-lg);
  }
  @media (--md) {
    font-size: var(--font-size-h2-md);
    line-height: var(--font-lh-h2-md);
  }
  @media (--sm) {
    font-size: var(--font-size-h2-sm);
    line-height: var(--font-lh-h2-sm);
  }
  @media (--xs) {
    font-size: var(--font-size-h2-xs);
    line-height: var(--font-lh-h2-xs);
  }
  @media (--xxs) {
    font-size: var(--font-size-h2-xxs);
    line-height: var(--font-lh-h2-xxs);
  }
}

.t-h3 {
  font-family: var(--font-title-stack);

  @media (--xl) {
    font-size: var(--font-size-h3-xl);
    line-height: var(--font-lh-h3-xl);
  }
  @media (--lg) {
    font-size: var(--font-size-h3-lg);
    line-height: var(--font-lh-h3-lg);
  }
  @media (--md) {
    font-size: var(--font-size-h3-md);
    line-height: var(--font-lh-h3-md);
  }
  @media (--sm) {
    font-size: var(--font-size-h3-sm);
    line-height: var(--font-lh-h3-sm);
  }
  @media (--xs) {
    font-size: var(--font-size-h3-xs);
    line-height: var(--font-lh-h3-xs);
  }
  @media (--xxs) {
    font-size: var(--font-size-h3-xxs);
    line-height: var(--font-lh-h3-xxs);
  }
}

.t-h4 {
  @media (--xl) {
    font-size: var(--font-size-h4-xl);
    line-height: var(--font-lh-h4-xl);

    p + p {
      margin-top: var(--font-lh-h4-xl);
    }
  }
  @media (--lg) {
    font-size: var(--font-size-h4-lg);
    line-height: var(--font-lh-h4-lg);

    p + p {
      margin-top: var(--font-lh-h4-lg);
    }
  }
  @media (--md) {
    font-size: var(--font-size-h4-md);
    line-height: var(--font-lh-h4-md);

    p + p {
      margin-top: var(--font-lh-h4-md);
    }
  }
  @media (--sm) {
    font-size: var(--font-size-h4-sm);
    line-height: var(--font-lh-h4-sm);

    p + p {
      margin-top: var(--font-lh-h4-sm);
    }
  }
  @media (--xs) {
    font-size: var(--font-size-h4-xs);
    line-height: var(--font-lh-h4-xs);

    p + p {
      margin-top: var(--font-lh-h4-xs);
    }
  }
  @media (--xxs) {
    font-size: var(--font-size-h4-xxs);
    line-height: var(--font-lh-h4-xxs);

    p + p {
      margin-top: var(--font-lh-h4-xxs);
    }
  }
}

.t-h5 {
  font-family: var(--font-title-stack);

  @media (--xl) {
    font-size: var(--font-size-h5-xl);
    line-height: var(--font-lh-h5-xl);
  }
  @media (--lg) {
    font-size: var(--font-size-h5-lg);
    line-height: var(--font-lh-h5-lg);
  }
  @media (--md) {
    font-size: var(--font-size-h5-md);
    line-height: var(--font-lh-h5-md);
  }
  @media (--sm) {
    font-size: var(--font-size-h5-sm);
    line-height: var(--font-lh-h5-sm);
  }
  @media (--xs) {
    font-size: var(--font-size-h5-xs);
    line-height: var(--font-lh-h5-xs);
  }
  @media (--xxs) {
    font-size: var(--font-size-h5-xxs);
    line-height: var(--font-lh-h5-xxs);
  }
}

.t-body {
  @media (--xl) {
    font-size: var(--font-size-body-xl);
    line-height: var(--font-lh-body-xl);
  }
  @media (--lg) {
    font-size: var(--font-size-body-lg);
    line-height: var(--font-lh-body-lg);
  }
  @media (--md) {
    font-size: var(--font-size-body-md);
    line-height: var(--font-lh-body-md);
  }
  @media (--sm) {
    font-size: var(--font-size-body-sm);
    line-height: var(--font-lh-body-sm);
  }
  @media (--xs) {
    font-size: var(--font-size-body-xs);
    line-height: var(--font-lh-body-xs);
  }
  @media (--xxs) {
    font-size: var(--font-size-body-xxs);
    line-height: var(--font-lh-body-xxs);
  }
}

.t-caption {
  @media (--xl) {
    font-size: var(--font-size-caption-xl);
    line-height: var(--font-lh-caption-xl);
  }
  @media (--lg) {
    font-size: var(--font-size-caption-lg);
    line-height: var(--font-lh-caption-lg);
  }
  @media (--md) {
    font-size: var(--font-size-caption-md);
    line-height: var(--font-lh-caption-md);
  }
  @media (--sm) {
    font-size: var(--font-size-caption-sm);
    line-height: var(--font-lh-caption-sm);
  }
  @media (--xs) {
    font-size: var(--font-size-caption-xs);
    line-height: var(--font-lh-caption-xs);
  }
  @media (--xxs) {
    font-size: var(--font-size-caption-xxs);
    line-height: var(--font-lh-caption-xxs);
  }
}

.t-uc {
  text-transform: uppercase;
}

.t-strong {
  font-weight: 700;
}

.t-h4 {
  strong {
    font-family: var(--font-title-stack);
    font-weight: 400;
  }
}

.blockquote {
  @extend .t-h4;
  color: var(--color-highlight);

  &:before {
    content: '“';
    display: block;
    font-size: 45px; 
    font-family: var(--font-title-stack);
    line-height: 0.8;
    height: 0.45em;
  }
}

.text-content {
  h1, h2, h3, h4, h5, blockquote {
    margin: var(--margin-30) 0;

    @media (width >= #{$size-sm}) {
      margin: var(--margin-45) 0;
    }
  }

  p, ul, ol {
    @media (--xl) {
      margin: var(--font-lh-body-xl) 0;
    }
    @media (--lg) {
      margin: var(--font-lh-body-lg) 0;
    }
    @media (--md) {
      margin: var(--font-lh-body-md) 0;
    }
    @media (--sm) {
      margin: var(--font-lh-body-sm) 0;
    }
    @media (--xs) {
      margin: var(--font-lh-body-xs) 0;
    }
    @media (--xxs) {
      margin: var(--font-lh-body-xxs) 0;
    }
  }

  p.large {
    @media (--xl) {
      margin: var(--font-lh-h4-xl) 0;
    }
    @media (--lg) {
      margin: var(--font-lh-h4-lg) 0;
    }
    @media (--md) {
      margin: var(--font-lh-h4-md) 0;
    }
    @media (--sm) {
      margin: var(--font-lh-h4-sm) 0;
    }
    @media (--xs) {
      margin: var(--font-lh-h4-xs) 0;
    }
    @media (--xxs) {
      margin: var(--font-lh-h4-xxs) 0;
    }
  }

  h5 + p {
    margin-top: calc(var(--margin-30) * -1);

    @media (width >= #{$size-sm}) {
      margin-top: calc(var(--margin-45) * -1);
    }
  }

  p:not(.t-large), ul:not(.t-large), ol:not(.t-large) {
    @extend .t-body;
  }

  .t-large {
    @extend .t-h4;
  }

  h3 {
    @extend .t-h3;
  }

  h5 {
    @extend .t-h5;
  }

  blockquote {
    @extend .blockquote;
  }

  a {
    font-weight: 700;

    @extend .underline-hover;
  }

  strong {
    font-family: var(--font-title-stack);
    font-weight: 400;
  }

  ul,
  ol {
    padding: 0 0 0 1.2em;
  }

  ul {
    list-style: disc;
  }

  ul ul {
    list-style-type: circle;
  }

  ul ul ul {
    list-style-type: square;
  }

  ol ol {
    list-style-type: lower-roman;
  }

  ol ol ol {
    list-style-type: lower-alpha;
  }

  hr {
    @extends .divider;
  }
  
  & > * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.t-color-links {
  a {
    color: var(--color-highlight);
    font-weight: 700;
  }
}