.site-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  padding-top: var(--margin-90);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  opacity: 0;

  html:not(.is-nav-active) & {
    display: none;
  }
}

.site-nav__top {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.site-nav__menu {
  display: flex;
  flex-wrap: wrap;

  @media (--sm) {
    margin: calc(var(--margin-30) * -1) 0;
  }
}

.site-nav__pillar {
  width: 100%;

  @media (width < #{$size-sm}) {
    &:not(:last-child) {
      margin-bottom: var(--margin-45);
    }
  }

  @media (--sm) {
    width: 50%;
    padding: var(--margin-30) 0;
  }

  @media (width >= #{$size-md}) {
    width: 25%;
  }
}

.site-nav__footer {
  @media (width >= #{$size-md}) {
    display: flex;
  }

  @media (width >= #{$size-sm}) {
    .social-icon {
      width: 22px;
      height: 22px;
    }
  }
}

.site-nav__footer-links {
  display: flex;
  flex-wrap: wrap;
}

.site-nav__footer-link {
  @media (width < #{$size-sm}) {
    width: 100%;
  }

  @media (--sm) {
    width: 50%;
  }

  @media (width >= #{$size-md}) {
    margin-right: var(--margin-30);
  }
}