.underline-hover {
  background-image: linear-gradient(var(--color-white), var(--color-white));
  background-size: 0% 2px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 3px;
  transition: background-size 350ms;

  .t-color-black & {
    background-image: linear-gradient(var(--color-black), var(--color-black));
  }

  @media (hover: hover) {
    &:hover {
      background-size: 100% 2px;
      background-position: 0 100%;
    }
  }

  &.underline-hover--perma {
    background-size: 100% 2px;
    background-position: 0 100%;
  }
}

.scroll-link {
  svg {
    transition: transform 350ms;
  }

  @media (hover: hover) {
    &:hover {
      svg {
        transform: translateY(5px);
      }
    }
  }
}

@keyframes bob {
  0%{
    transform: translate(0, -50%);
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100%{
    transform: translate(0, 25%);
    opacity: 0;
  }
}

.proceed {
  cursor: pointer;
  
  svg {
    animation: bob 2000ms infinite cubic-bezier(0.455, 0.030, 0.515, 0.955);
  }
}