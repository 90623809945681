.site-header-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--header-height);
  z-index: 99;
  background-color: var(--color-highlight);
}

.site-header {
  position: sticky;
  top: 0px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--header-height);
  padding: 0 var(--header-padding);
  transition: background-color 350ms 0ms;

  @media (width < #{$size-md}) {
    &[class*="bg"] {
      background-color: transparent;
    }
  }

  @media (width >= #{$size-md}) {
    html:not(.is-nav-transition-active) .is-scrolled[data-scroll-dir="down"] & {
      background-color: transparent;
      pointer-events: none;

      & > * {
        pointer-events: all;
      }
    }
  }

  .is-nav-transition-active & {
    /* position: fixed;
    width: 100%; */
    background-color: var(--color-red);
  }
}

.site-header__menu {
  display: flex;
  align-items: center;
}

.site-header__nav-icon,
.site-header__logo {
  height: var(--button-size);
  width: var(--button-size);
  border: 2px solid;
  border-radius: 100%;
  background-color: inherit;
  display: flex;
  align-items: center;
  transition: border-color 350ms, background-color 350ms;
  background-color: var(--color-highlight);

  &:focus {
    outline: 0;
  }

  @media (hover: hover) {
    &:hover,
    &:focus {
      border-color: var(--color-highlight);
      background-color: var(--color-text);

      svg {
        fill: var(--color-highlight);
      }

      span {
        background-color: var(--color-highlight);
      }
    }
  }

  .is-nav-transition-active & {
    background-color: transparent;
    border-color: var(--color-white);

    @media (hover: hover) {
      &:hover,
      &:focus {
        background-color: var(--color-white);
        border-color: transparent;

        svg {
          fill: var(--color-red);
        }

        span {
          background-color: var(--color-red);
        }
      }
    }
  }
}

.site-header__logo {
  svg {
    margin-left: 6px;
    margin-bottom: 1px;
    transition: fill 350ms;

    .is-nav-transition-active & {
      fill: var(--color-white);
    }
  }
}

.site-header__nav {
  display: flex;

  a {
    margin-right: var(--header-padding);
    border-bottom: 2px solid transparent;
    margin-top: 4px;

    &.is-active {
      border-color: inherit;
    }
  }

  @media (width < $size-md) {
    display: none;
  } 

  .is-scrolled[data-scroll-dir="down"] & {
    opacity: 0;
    pointer-events: none;
  }
}

.site-header__nav,
.site-header__donate {
  transition: opacity 350ms;

  .is-nav-transition-active & {
    opacity: 0;
    pointer-events: none;
  }
}

.site-header__nav-icon {
  position: relative;
  margin-left: var(--header-padding);
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    width: 26px;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: all 350ms;

    .is-nav-transition-active & {
      background-color: var(--color-white);
    }

    &:nth-child(1) {
      transform: translate(-50%, -8px);
    }

    &:nth-child(2) {
      transform: translate(-50%, 0);
    }

    &:nth-child(3) {
      transform: translate(-50%, 8px);
    }
  }

  .is-nav-transition-active & {
    span {
      &:nth-child(1) {
        transform: translate(-50%, 0) rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: translate(-50%, 0) rotate(-45deg);
      }
    }
  }
}

.site-header__donate-button {
  border: 2px solid transparent;

  .bg-color-green & {
    border-color: inherit;
  }
}

.site-header__title {
  display: flex;
  align-items: center;
}

.site-header__location {
  opacity: 0;
  transition: opacity 350ms;

  @media (width < #{$size-sm}) {
    display: none;
  }

  html:not(.is-nav-transition-active) .is-scrolled[data-scroll-dir="up"] & {
    opacity: 1;
  }

  .is-nav-transition-active & {
    opacity: 0;
  }
}