.lazyload,
.lazyloading {
  opacity: 0;

  &.lazyload--static {
    opacity: 1;
  }
}

.lazyload--fade {
  transition: opacity 350ms 0ms;
  backface-visibility: hidden;
}

.lazyload--slide-up {
  transition: opacity 500ms var(--ease-in-out), transform 500ms var(--ease-in-out), -webkit-transform 500ms var(--ease-in-out);
  backface-visibility: hidden;

  &.lazyload,
  &.lazyloading {
    opacity: 0;
    transform: translateY(50px);
  }
}