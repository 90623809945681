.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%; 
  display: none;
  animation: fadeIn 250ms 750ms;
  animation-fill-mode: both;
  pointer-events: none;

  .is-loading & {
    display: block;
  }
}

.inline-loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%; 
  display: none;
  animation: fadeIn 250ms;
  animation-fill-mode: both;

  .lazyloading + &,
  &.inline-loader--visible {
    display: block;
  }

  &:not(.inline-loader--visible) {
    animation-delay: 750ms;
  }
}

.loader__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  width: 30px;
  transform: translate(-50%, -50%);

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.loader__circular {
  animation: rotate 1.5s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.loader__circular--static {
  position: static;
  display: block;
  width: 30px;
  height: 30px;
  margin: 0 auto;
}

.loader__path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1s ease-in-out infinite;
  stroke-linecap: round;
  stroke: #000;
  stroke: var(--color-highlight);
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

.article-grid-loader__button {
  text-align: center;
  cursor: pointer;
  display: none;
}

.article-grid-loader {
  position: relative;
  display: flex;
  align-items: center;

  .loader__circular {
    visibility: hidden;
  }

  &.is-loading {
    .loader__circular {
      visibility: visible;
    }
  }
}