:root {
  --ios-gap: 0.01px;
  --font-sans-stack: "Archivo", "Helvetica", "Arial", sans-serif;
  --font-title-stack: "Archivo Black", "Archivo", "Helvetica", "Arial", sans-serif;
  --ease-in-out: cubic-bezier(0.215, 0.610, 0.355, 1.000);
}

@custom-media --xxs (width < #{$size-xs});
@custom-media --xs (width >= #{$size-xs}) and (width < #{$size-sm});
@custom-media --sm (width >= #{$size-sm}) and (width < #{$size-md});
@custom-media --md (width >= #{$size-md}) and (width < #{$size-lg});
@custom-media --lg (width >= #{$size-lg}) and (width < #{$size-xl});
@custom-media --xl (width >=#{$size-xl});

:root {
  --color-red: #DB3347;
  --color-red-alt: #AC2545;
  --color-yellow: #FFEB33;
  --color-yellow-alt: #E3D636;
  --color-orange: #FE7838;
  --color-orange-alt: #F99252;
  --color-pink: #E71FA4;
  --color-pink-alt: #B6077C;
  --color-green: #9DE251;
  --color-green-alt: #87D34B;
  --color-blue: #1D377D;
  --color-blue-alt: #16295F;
  --color-black: #000;
  --color-white: #fff;
  --color-grey: #D3D3D3;

  --color-red-tint: #F9E1E4;
  --color-orange-tint: #FEEBE2;
  --color-pink-tint: #FDE1F2;
  --color-green-tint: #F2FAE6;
  --color-blue-tint: #DDE1EB;
}

:root {
  --font-size-h1-xl: 46px;
  --font-size-h1-lg: 46px;
  --font-size-h1-md: 44px;
  --font-size-h1-sm: 46px;
  --font-size-h1-xs: 28px;
  --font-size-h1-xxs: 28px;

  --font-lh-h1-xl: 62px;
  --font-lh-h1-lg: 62px;
  --font-lh-h1-md: 60px;
  --font-lh-h1-sm: 62px;
  --font-lh-h1-xs: 38px;
  --font-lh-h1-xxs: 38px;

  --font-size-h2-xl: 38px;
  --font-size-h2-lg: 38px;
  --font-size-h2-md: 32px;
  --font-size-h2-sm: 38px;
  --font-size-h2-xs: 24px;
  --font-size-h2-xxs: 24px;

  --font-lh-h2-xl: 54px;
  --font-lh-h2-lg: 54px;
  --font-lh-h2-md: 44px;
  --font-lh-h2-sm: 54px;
  --font-lh-h2-xs: 32px;
  --font-lh-h2-xxs: 32px;

  --font-size-h3-xl: 26px;
  --font-size-h3-lg: 26px;
  --font-size-h3-md: 22px;
  --font-size-h3-sm: 26px;
  --font-size-h3-xs: 20px;
  --font-size-h3-xxs: 20px;

  --font-lh-h3-xl: 36px;
  --font-lh-h3-lg: 36px;
  --font-lh-h3-md: 32px;
  --font-lh-h3-sm: 36px;
  --font-lh-h3-xs: 30px;
  --font-lh-h3-xxs: 30px;

  --font-size-h4-xl: 26px;
  --font-size-h4-lg: 26px;
  --font-size-h4-md: 22px;
  --font-size-h4-sm: 26px;
  --font-size-h4-xs: 20px;
  --font-size-h4-xxs: 20px;

  --font-lh-h4-xl: 36px;
  --font-lh-h4-lg: 36px;
  --font-lh-h4-md: 32px;
  --font-lh-h4-sm: 36px;
  --font-lh-h4-xs: 30px;
  --font-lh-h4-xxs: 30px;

  --font-size-h5-xl: 17px;
  --font-size-h5-lg: 17px;
  --font-size-h5-md: 17px;
  --font-size-h5-sm: 17px;
  --font-size-h5-xs: 16px;
  --font-size-h5-xxs: 16px;

  --font-lh-h5-xl: 24px;
  --font-lh-h5-lg: 24px;
  --font-lh-h5-md: 24px;
  --font-lh-h5-sm: 24px;
  --font-lh-h5-xs: 22px;
  --font-lh-h5-xxs: 22px;

  --font-size-body-xl: 17px;
  --font-size-body-lg: 17px;
  --font-size-body-sm: 17px;
  --font-size-body-md: 17px;
  --font-size-body-xs: 16px;
  --font-size-body-xxs: 16px;

  --font-lh-body-xl: 24px;
  --font-lh-body-lg: 24px;
  --font-lh-body-md: 24px;
  --font-lh-body-sm: 24px;
  --font-lh-body-xs: 22px;
  --font-lh-body-xxs: 22px;

  --font-size-caption-xl: 12px;
  --font-size-caption-lg: 12px;
  --font-size-caption-sm: 12px;
  --font-size-caption-md: 12px;
  --font-size-caption-xs: 11px;
  --font-size-caption-xxs: 11px;

  --font-lh-caption-xl: 18px;
  --font-lh-caption-lg: 18px;
  --font-lh-caption-md: 18px;
  --font-lh-caption-sm: 18px;
  --font-lh-caption-xs: 16px;
  --font-lh-caption-xxs: 16px;
}

:root {
  --margin-90: 90px;
  --margin-45: 45px;
  --margin-30: 30px;
  --margin-20: 20px;
  --margin-15: 15px;
  --margin-10: 10px;

  --gutter-xl: 90px;
  --gutter-lg: 90px;
  --gutter-md: 45px;
  --gutter-sm: 45px;
  --gutter-xs: 20px;
  --gutter-xxs: 20px;

  --button-size: 50px;

  --header-height: 90px;
  --header-padding: 20px;
  
  --rip-size: 2560px;
}