.form-wrap,
.form-inner {
  position: relative;
}

.form-page {
  padding: var(--margin-15);

  @media (--xl) {
    font-size: var(--font-size-body-xl);
    line-height: var(--font-lh-body-xl);
  }
  @media (--lg) {
    font-size: var(--font-size-body-lg);
    line-height: var(--font-lh-body-lg);
  }
  @media (--md) {
    font-size: var(--font-size-body-md);
    line-height: var(--font-lh-body-md);
  }
  @media (--sm) {
    font-size: var(--font-size-body-sm);
    line-height: var(--font-lh-body-sm);
  }
  @media (--xs) {
    font-size: var(--font-size-body-xs);
    line-height: var(--font-lh-body-xs);
  }
  @media (--xxs) {
    font-size: var(--font-size-body-xxs);
    line-height: var(--font-lh-body-xxs);
  }

  @media (width >= #{$size-sm}) {
    padding: var(--margin-30);
  }
}

.wFormTitle {
  display: none;
}

.wForm,
.freeform-form {
  padding: 0;
  font-family: var(--font-sans-stack);

  *[style*="font"],
  font {
    font-family: inherit !important;
    font-size: inherit !important; 
  }

  *[style*="color"] {
    color: inherit !important;
  }

  *[style*="background-color"] {
    background-color: transparent !important;
  }

  .actions {
    margin-top: var(--margin-30);
  }
}

.wForm fieldset {
  border: 0;
  border-bottom: 1px solid var(--color-grey);
  margin-bottom: var(--margin-15);
  padding-bottom: var(--margin-15);

  &:last-child {
    margin-bottom: 0;
  }

  &:focus {
    outline: 0;
  }
}

.wForm fieldset legend {
  font-family: var(--font-title-stack);
  margin-bottom: var(--margin-10);

  b, strong {
    font-weight: inherit;
  }

  @media (--xl) {
    font-size: var(--font-size-h5-xl);
    line-height: var(--font-lh-h5-xl);
  }
  @media (--lg) {
    font-size: var(--font-size-h5-lg);
    line-height: var(--font-lh-h5-lg);
  }
  @media (--md) {
    font-size: var(--font-size-h5-md);
    line-height: var(--font-lh-h5-md);
  }
  @media (--sm) {
    font-size: var(--font-size-h5-sm);
    line-height: var(--font-lh-h5-sm);
  }
  @media (--xs) {
    font-size: var(--font-size-h5-xs);
    line-height: var(--font-lh-h5-xs);
  }
  @media (--xxs) {
    font-size: var(--font-size-h5-xxs);
    line-height: var(--font-lh-h5-xxs);
  }

  * {
    font-size: inherit !important;
    line-height: inherit !important;
  }
}

.wForm .inline {
  @media (width < #{$size-sm}) {
    & > :last-child {
      margin-bottom: 8px;
    }
  }

  @media (width >= #{$size-sm}) {
    display: flex;
    flex-wrap: wrap;

    & > * {
      flex: 1 1 auto;
      width: 50%;
    }

    & > *:not(:last-child) {
      padding-right: 8px;
    }
  }
}

.wForm,
.freeform-form {
  .label,
  .freeform-label {
    display: inline-block;
    padding-bottom: 8px;
  }

  .freeform-instructions {
    padding-bottom: 8px;
  }

  .htmlSection {
    margin-bottom: var(--margin-15);
  }

  .oneField:not(:last-child) {
    margin-bottom: 8px;
  }

  .reqMark {
    &:after {
      content: ' *';
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="number"],
  input[type="url"],
  select,
  textarea {
    width: 100%;
    padding: 8px;
    box-shadow: 0 0 0 1px var(--color-grey);

    .bg-color-blue & {
      background-color: var(--color-blue-tint);
      /* color: var(--color-blue-alt); */

      &:focus {
        outline: 2px solid var(--color-blue-alt);
      }
    }

    .bg-color-red & {
      background-color: var(--color-red-tint);
      /* color: var(--color-red-alt); */

      &:focus {
        outline: 2px solid var(--color-red-alt);
      }
    }

    .bg-color-orange & {
      background-color: var(--color-orange-tint);
      /* color: var(--color-orange); */

      &:focus {
        outline: 2px solid var(--color-orange);
      }
    }

    .bg-color-pink & {
      background-color: var(--color-pink-tint);
      /* color: var(--color-pink-alt); */

      &:focus {
        outline: 2px solid var(--color-pink-alt);
      }
    }

    .bg-color-green & {
      background-color: var(--color-green-tint);
      /* color: var(--color-green-alt); */

      &:focus {
        outline: 2px solid var(--color-green-alt);
      }
    }
  }

  .choices {
    display: flex;

    &.vertical {
      flex-direction: column;
    }

    .oneChoice {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      .label {
        padding: 0;
      }

      input {
        margin-right: 8px;
      }
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    width: 26px;
    height: 26px;
    flex: 0 0 auto;
    box-shadow: 0 0 0 1px var(--color-grey);

    .bg-color-blue & {
      background-color: var(--color-blue-tint);

      &:checked {
        background-color: var(--color-blue);
        border: 5px solid var(--color-blue-tint);
      }
    }

    .bg-color-red & {
      background-color: var(--color-red-tint);

      &:checked {
        background-color: var(--color-red);
        border: 5px solid var(--color-red-tint);
      }
    }

    .bg-color-orange & {
      background-color: var(--color-orange-tint);

      &:checked {
        background-color: var(--color-orange);
        border: 5px solid var(--color-orange-tint);
      }
    }

    .bg-color-pink & {
      background-color: var(--color-pink-tint);

      &:checked {
        background-color: var(--color-pink);
        border: 5px solid var(--color-pink-tint);
      }
    }

    .bg-color-green & {
      background-color: var(--color-green-tint);

      &:checked {
        background-color: var(--color-green);
        border: 5px solid var(--color-green-tint);
      }
    }
  }

  input[type="radio"] {
    border-radius: 100%;
  }

  .field-hint,
  .field-hint-inactive {
    position: absolute;
  }

  .field-hint-inactive {
    display: none;
  }

  .hint {
    width: 100%;
    padding: 8px;
    display: block;

    .bg-color-blue & {
      color: var(--color-white);
      background-color: var(--color-blue-alt);
      outline: 2px solid var(--color-blue-alt);
    }

    .bg-color-red & {
      color: var(--color-white);
      background-color: var(--color-red-alt);
      outline: 2px solid var(--color-red-alt);
    }

    .bg-color-orange & {
      color: var(--color-black);
      background-color: var(--color-orange-alt);
      outline: 2px solid var(--color-orange-alt);
    }

    .bg-color-pink & {
      color: var(--color-white);
      background-color: var(--color-pink-alt);
      outline: 2px solid var(--color-pink-alt);
    }

    .bg-color-green & {
      color: var(--color-black);
      background-color: var(--color-green-alt);
      outline: 2px solid var(--color-green-alt);
    }
  }

  select {
    background-position: calc(100% - 8px) 50%;
    background-repeat: no-repeat;
    background-size: 15px 9px;

    .bg-color-blue & {
      background-image: url('../img/icon-arrow-blue.svg');
    }

    .bg-color-red & {
      background-image: url('../img/icon-arrow-red.svg');
    }

    .bg-color-orange & {
      background-image: url('../img/icon-arrow-orange.svg');
    }

    .bg-color-pink & {
      background-image: url('../img/icon-arrow-pink.svg');
    }

    .bg-color-green & {
      background-image: url('../img/icon-arrow-green.svg');
    }
  }

  input[type="button"],
  input[type="submit"],
  button {
    font-family: var(--font-title-stack);
    text-transform: uppercase;
    min-height: var(--button-size);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--button-size);
    padding: 12px calc(var(--button-size) * 0.5) var(--margin-10);
    transition: color 350ms, background-color 350ms, border-color 350ms;
    border: 2px solid;
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        color: var(--color-white);
        background-color: var(--color-black);
        border-color: var(--color-black);
      }
    }

    @media (--xl) {
      font-size: var(--font-size-h5-xl);
      line-height: var(--font-lh-h5-xl);
    }
    @media (--lg) {
      font-size: var(--font-size-h5-lg);
      line-height: var(--font-lh-h5-lg);
    }
    @media (--md) {
      font-size: var(--font-size-h5-md);
      line-height: var(--font-lh-h5-md);
    }
    @media (--sm) {
      font-size: var(--font-size-h5-sm);
      line-height: var(--font-lh-h5-sm);
    }
    @media (--xs) {
      font-size: var(--font-size-h5-xs);
      line-height: var(--font-lh-h5-xs);
    }
    @media (--xxs) {
      font-size: var(--font-size-h5-xxs);
      line-height: var(--font-lh-h5-xxs);
    }
  }
}

.wForm form .wfPagingButtons {
  margin-right: var(--margin-20);
  margin-top: var(--margin-30);

  @media (width >= #{$size-sm}) {
    float: left;
  }
}

.wFormFooter {
  margin-top: var(--margin-30);
  display: none;

  .supportInfo {
    @media (--xl) {
      font-size: var(--font-size-caption-xl);
      line-height: var(--font-lh-caption-xl);
    }
    @media (--lg) {
      font-size: var(--font-size-caption-lg);
      line-height: var(--font-lh-caption-lg);
    }
    @media (--md) {
      font-size: var(--font-size-caption-md);
      line-height: var(--font-lh-caption-md);
    }
    @media (--sm) {
      font-size: var(--font-size-caption-sm);
      line-height: var(--font-lh-caption-sm);
    }
    @media (--xs) {
      font-size: var(--font-size-caption-xs);
      line-height: var(--font-lh-caption-xs);
    }
    @media (--xxs) {
      font-size: var(--font-size-caption-xxs);
      line-height: var(--font-lh-caption-xxs);
    }
  }
}

.wFormContainer .errMsg {
  color: var(--color-red);
  font-weight: 700;
  padding-top: 4px;

  @media (--xl) {
    font-size: var(--font-size-caption-xl);
    line-height: var(--font-lh-caption-xl);
  }
  @media (--lg) {
    font-size: var(--font-size-caption-lg);
    line-height: var(--font-lh-caption-lg);
  }
  @media (--md) {
    font-size: var(--font-size-caption-md);
    line-height: var(--font-lh-caption-md);
  }
  @media (--sm) {
    font-size: var(--font-size-caption-sm);
    line-height: var(--font-lh-caption-sm);
  }
  @media (--xs) {
    font-size: var(--font-size-caption-xs);
    line-height: var(--font-lh-caption-xs);
  }
  @media (--xxs) {
    font-size: var(--font-size-caption-xxs);
    line-height: var(--font-lh-caption-xxs);
  }
} 

.wForm .inputWrapper {
  display: flex;
  align-items: center;

  .delimeter:empty {
    display: none;
  }

  .delimiterBefore {
    margin-right: 8px;
  }

  .delimiterAfter {
    margin-right: 8px;
  }
}

.form-rip--top {
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1, -1);
}

.form-rip--bottom {
  position: absolute;
  bottom: 0;
  left: 0;
}

.form-rip {
  overflow: hidden;
  height: 336px;
  width: 100%;

  svg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    height: 100%;
    width: 2560px;
    min-width: 100%;
  }
}

.wFormThankYou {
  font-weight: 700;
}

.kalendae,
.kalendae *{
  box-sizing: content-box;
}

/* LAYOUT */

.freeform-form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.freeform-row {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
}

button[type='submit'].ff-loading:before {
  content: '';
  display: block;
  flex: 1 0 11px;
  width: 11px;
  height: 11px;
  margin-right: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: transparent transparent #fff #fff;
  border-radius: 50%;
  animation: ff-loading 0.5s linear infinite;
}

@keyframes ff-loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
}

/* PAGE TABS */
.freeform-pages {
  display: flex;
  padding: 0;
  margin: 0 0 25px 0;
  list-style: none;
  border-bottom: 2px solid #dddddd;
}
.freeform-pages li {
  color: #6c757d;
  background: transparent;
  padding: 10px 20px;
  margin: 0;
}
.freeform-pages li.active {
  color: black;
  font-weight: bold;
  background: #dddddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

/* FORM LAYOUT */
.freeform-row [class*='freeform-col-'] {
  margin: 0;
  box-sizing: border-box;
}

.freeform-row [class*='freeform-col-'] > .freeform-row:first-child {
  margin-top: -10px;
}

.freeform-row
  [class*='freeform-col-']
  label:has(> .freeform-input[type='checkbox'].freeform-has-errors),
.freeform-row
  [class*='freeform-col-']
  label:has(> .freeform-input[type='radio'].freeform-has-errors),
.freeform-row
  [class*='freeform-col-'].freeform-fieldtype-checkbox_group:has(
    ul.freeform-errors
  )
  label:not(:first-child),
.freeform-row
  [class*='freeform-col-'].freeform-fieldtype-checkbox_group:has(
    ul.freeform-errors
  )
  div.input-group-one-line
  label,
.freeform-row
  [class*='freeform-col-'].freeform-fieldtype-radio_group:has(
    ul.freeform-errors
  )
  label:not(:first-child),
.freeform-row
  [class*='freeform-col-'].freeform-fieldtype-radio_group:has(
    ul.freeform-errors
  )
  div.input-group-one-line
  label,
  .freeform-errors {
  color: #dc3545 !important;
}

.freeform-row [class*='freeform-col-'] .input-group-one-line {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.freeform-row [class*='freeform-col-'] .input-group-one-line label {
  padding-right: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.freeform-row
  [class*='freeform-col-']
  .input-group-one-line
  label
  input[type='radio'],
.freeform-row
  [class*='freeform-col-']
  .input-group-one-line
  label
  input[type='checkbox'] {
  margin-right: 10px;
  display: inline-block;
}

.freeform-row [class*='freeform-col-'] .freeform-label.freeform-required:after {
  content: '*';
  margin-left: 5px;
  color: #dc3545;
}

.freeform-row.freeform-payments {
  margin: -10px;
}

/* SUBMIT BUTTON ALIGNMENT */
.freeform-row [class*='freeform-col-'].freeform-column-content-align-left {
  display: flex;
  justify-content: flex-start;
}
.freeform-row
  [class*='freeform-col-'].freeform-column-content-align-left
  > button:not(:first-of-type) {
  margin-left: 5px;
}
.freeform-row [class*='freeform-col-'].freeform-column-content-align-center {
  display: flex;
  justify-content: center;
}
.freeform-row
  [class*='freeform-col-'].freeform-column-content-align-center
  > button:not(:first-of-type) {
  margin-left: 5px;
}
.freeform-row [class*='freeform-col-'].freeform-column-content-align-right {
  display: flex;
  justify-content: flex-end;
}
.freeform-row
  [class*='freeform-col-'].freeform-column-content-align-right
  > button:not(:first-of-type) {
  margin-left: 5px;
}
.freeform-row [class*='freeform-col-'].freeform-column-content-align-spread {
  display: flex;
  justify-content: space-between;
}
.freeform-row
  [class*='freeform-col-'].freeform-column-content-align-spread
  > button:not(:first-of-type) {
  margin-left: 5px;
}

/* ERRORS AND SUCCESS */
.freeform-form-success,
.freeform-form-errors {
  padding: 15px 20px;
}

.freeform-form-success p,
.freeform-form-errors p {
  font: normal 18px sans-serif;
  color: #ffffff;
  margin: 0;
}

.freeform-form-success ul,
.freeform-form-errors ul {
  font: italic 15px sans-serif;
  color: #ffffff;
  margin: 10px 0 0 0;
  padding: 0 0 0 25px;
  list-style: disc;
}

.freeform-form-success ul li:not(:last-child),
.freeform-form-errors ul li:not(:last-child) {
  margin-bottom: 5px;
}

.freeform-form-success {
  background-color: #198754;
  border: 1px solid #198754;
}

.freeform-form-errors {
  background-color: #dc3545;
  border: 1px solid #dc3545;
}

/* RESPONSIVE GRID */
@media only screen and (max-width: 800px) {
  [class*='freeform-col-'] {
    width: 100%;
  }
}

@media only screen and (min-width: 801px) {
  .freeform-row {
    grid-template-columns: repeat(12, 1fr);
  }
  
  .freeform-col-1 {
    grid-column: span 1;
  }
  .freeform-col-2 {
    grid-column: span 2;
  }
  .freeform-col-3 {
    grid-column: span 3;
  }
  .freeform-col-4 {
    grid-column: span 4;
  }
  .freeform-col-5 {
    grid-column: span 5;
  }
  .freeform-col-6 {
    grid-column: span 6;
  }
  .freeform-col-7 {
    grid-column: span 7;
  }
  .freeform-col-8 {
    grid-column: span 8;
  }
  .freeform-col-9 {
    grid-column: span 9;
  }
  .freeform-col-10 {
    grid-column: span 10;
  }
  .freeform-col-11 {
    grid-column: span 11;
  }
  .freeform-col-12 {
    grid-column: span 12;
  }
}