.article-card__inner {
  padding: var(--margin-20);
}

.card {
  transition: opacity 500ms var(--ease-in-out), transform 500ms var(--ease-in-out), -webkit-transform 500ms var(--ease-in-out);

  &.lazyload,
  &.lazyloading {
    opacity: 0;
    transform: translateY(50px);
  }
}

.card__inner {
  display: block;
}

.resource-card__header,
.resource-card__body {
  padding-right: var(--margin-20);
  padding-left: var(--margin-20);
}

@media (width >= #{$size-sm}) {
  .card-grid,
  .card-row {
    .card {
      display: flex;
      flex-direction: column;
    } 

    .card__inner {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      align-content: stretch;
    }

    .article-card__excerpt {
      flex: 1 0 auto;
    }
  }
}

.article-card__img {
  flex: 0 0 auto;
}

.resource-card__body,
.resource-card__inner {
  transition: background-color 350ms;
}

.resource-card__body {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.resource-card__excerpt {
  flex: 1 0 auto;
}

@media (hover: hover) {
  .article-card__inner {
    transition: background-color 350ms;

    svg {
      transition: fill 350ms;
    }

    &.bg-color-red {
      &:hover {
        background-color: var(--color-red-alt);

        svg {
          fill: var(--color-red-alt);
        }
      }
    }

    &.bg-color-red-alt {
      &:hover {
        background-color: var(--color-red);

        svg {
          fill: var(--color-red);
        }
      }
    }
  }

  .resource-card__inner {
    svg {
      transition: fill 350ms;
    }

    &.bg-color-orange {
      &:hover {
        background-color: var(--color-orange-alt);

        .resource-card__body {
          background-color: var(--color-orange);
        }

        svg {
          fill: var(--color-orange);
        }
      }
    }

    &.bg-color-pink {
      &:hover {
        background-color: var(--color-pink-alt);

        .resource-card__body {
          background-color: var(--color-pink);
        }

        svg {
          fill: var(--color-pink);
        }
      }
    }
  }
}