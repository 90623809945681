.t-color-white {
  color: var(--color-white);
}

.t-color-black {
  color: var(--color-black);
}

.fill-white {
  fill: var(--color-white);
}

.fill-black {
  fill: var(--color-black);
}

.bg-color-white {
  background-color: var(--color-white);
}

.bg-color-black {
  background-color: var(--color-black);
}

/* RED */

.bg-color-red {
  background-color: var(--color-red);
}

.bg-color-red-alt {
  background-color: var(--color-red-alt);
}

.fill-red {
  fill: var(--color-red);
}

.fill-red-alt {
  fill: var(--color-red-alt);
}

.t-color-red {
  color: var(--color-red);
}

.t-color-red-alt {
  color: var(--color-red-alt);
}

/* YELLOW */

.bg-color-yellow {
  background-color: var(--color-yellow);
}

.bg-color-yellow-alt {
  background-color: var(--color-yellow-alt);
}

.fill-yellow {
  fill: var(--color-yellow);
}

.fill-yellow-alt {
  fill: var(--color-yellow-alt);
}

.t-color-yellow {
  color: var(--color-yellow);
}

.t-color-yellow-alt {
  color: var(--color-yellow-alt);
}

/* ORANGE */

.bg-color-orange {
  background-color: var(--color-orange);
}

.bg-color-orange-alt {
  background-color: var(--color-orange-alt);
}

.fill-orange {
  fill: var(--color-orange);
}

.fill-orange-alt {
  fill: var(--color-orange-alt);
}

.t-color-orange {
  color: var(--color-orange);
}

.t-color-orange-alt {
  color: var(--color-orange-alt);
}

/* PINK */

.bg-color-pink {
  background-color: var(--color-pink);
}

.bg-color-pink-alt {
  background-color: var(--color-pink-alt);
}

.fill-pink {
  fill: var(--color-pink);
}

.fill-pink-alt {
  fill: var(--color-pink-alt);
}

.t-color-pink {
  color: var(--color-pink);
}

.t-color-pink-alt {
  color: var(--color-pink-alt);
}

/* GREEN */

.bg-color-green {
  background-color: var(--color-green);
}

.bg-color-green-alt {
  background-color: var(--color-green-alt);
}

.fill-green {
  fill: var(--color-green);
}

.fill-green-alt {
  fill: var(--color-green-alt);
}

.t-color-green {
  color: var(--color-green);
}

.t-color-green-alt {
  color: var(--color-green-alt);
}

/* BLUE */

.bg-color-blue {
  background-color: var(--color-blue);
}

.bg-color-blue-alt {
  background-color: var(--color-blue-alt);
}

.fill-blue {
  fill: var(--color-blue);
}

.fill-blue-alt {
  fill: var(--color-blue-alt);
}

.t-color-blue {
  color: var(--color-blue);
}

.t-color-blue-alt {
  color: var(--color-blue-alt);
}