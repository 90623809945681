.img-block {
  display: block;
  width: 100%;
  height: auto;
}

.wide-image {
  min-height: 500px;
  max-height: 1000px;
  height: 50vh;
  object-fit: cover;
  font-family: 'object-fit: cover;';

  @media (orientation: landscape) {
    height: 85vh;
  }
}

.wide-image-overlay {
  position: relative;
}

/* .wide-image-overlay {
  @media (width >= #{$size-md}) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
} */

.img-figure {
  figcaption {
    margin-top: var(--margin-15);
  }
}

.img-border {
  border: var(--margin-20) solid var(--color-highlight);
}

.img-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.responsive-embed {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}